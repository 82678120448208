// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-v-2-js": () => import("../src/templates/index-page-v2.js" /* webpackChunkName: "component---src-templates-index-page-v-2-js" */),
  "component---src-templates-contacts-js": () => import("../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-register-js": () => import("../src/templates/register.js" /* webpackChunkName: "component---src-templates-register-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kainos-jsx": () => import("../src/pages/kainos.jsx" /* webpackChunkName: "component---src-pages-kainos-jsx" */)
}

